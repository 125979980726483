import { Button, Image } from '@nextui-org/react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import Meta from '../components/Meta';
import { primaryButton } from '../theme/nextUI';

const Error_page = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Meta title={` 404 | ${t('Gamerg-GG')}`} />

      <div className="relative py-32 dark:bg-jacarta-800">
        <div className="container">
          <div className="text-center flex flex-col items-center gap-5">
            <Image src="/images/404.png" alt="404" className="mb-3" />

            <h1 className="font-display text-4xl md:text-6xl">
              {t('Page_Not_Found')}
            </h1>
            <p className="dark:text-jacarta-300 mb-5 text-lg leading-normal max-w-lg">
              {t('Ops_Message')}
            </p>
            <Link href="/">
              <Button as="a" className={primaryButton} size="lg" radius="full">
                {t('Navigate_Back_Home')}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error_page;
